:root {
    --blue: rgb(0, 32, 91);
}

.open-sans {
  font-family: "Open Sans", sans-serif;
}

.satisfy {
    font-family: "Satisfy", cursive;
  }
  

.bg-blue {
    background-color: var(--blue);
}

.text-blue {
    color: var(--blue);
}

.dark-drop-shadow {
    filter: drop-shadow(16px 16px 20px black);
}

.nav-item .nav-link {
    color: var(--blue);
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.nav-item .nav-link:hover {
    color: purple
}

.nav-item .nav-link:active {
    color: purple
}

.link-text {
    color: var(--blue);
}

.link-text:hover {
    color: purple;
}


.basic-page-setup {
    margin-top: 6vh;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    min-height: 80vh;
}

.superimpose {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img-filter {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 32, 91, 0.3);
}

.rolling-img {
    min-width: 100%;
    max-width: 150%;
    animation: movingImg 3s ease;
}

.rolling-img2 {
    min-width: 100%;
    max-width: 150%;
    animation: movingImgCopy 3s ease;
}

@keyframes movingImg {
    from {
        transform: translateX(0px);
        transform: translateY(0px);
        transform: scale(1.0);
    } to {
        transform: translateX(10px);
        transform: translateY(10px);
        transform: scale(1.07);
        /* filter: blur(2px); */
    }
}


@keyframes movingImgCopy {
    from {
        transform: translateX(0px);
        transform: translateY(0px);
        transform: scale(1.0);
    } to {
        transform: translateX(10px);
        transform: translateY(10px);
        transform: scale(1.07);
        /* filter: blur(2px); */
    }
}

.membership-bg-img {
    height: 100%;
    position: relative;
    left: -100%;
}

.membership-bg-img-frame {
    position: relative;
    overflow: hidden;
}

.payment-img-frame {
    width: 40vw;
    height: 94vh;
    overflow: hidden;
}

.payment-img {
    height: 100%;
}

.payment-btn-frame {
    width: 55%;
    height: 90vh;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.payment-cover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 900;
}

.paypal-wrapper {
    width: 80%;
    overflow: auto;
}

@media only screen and (max-width: 768px) {
    .membership-bg-img {
        position: relative;
        width: 105%;
        height: auto;
        left: 0px;
        top: 0px;
    }

    .membership-bg-img-frame {
        height: fit-content !important;
        width: 100%;
    }

    .payment-img-frame {
        width: 100vw;
        height: fit-content;
    }

    .payment-img {
        width: 100%;
        height: auto;
    }

    .payment-btn-frame {
        width: 100%;
        height: fit-content;
    }

    .rolling-img {
        height: 100%;
        width: auto;
        max-width: none;
        position: relative;
        left: -80%;
    }

    .rolling-img2 {
        height: 100%;
        width: auto;
        max-width: none;
        position: relative;
        left: -80%;
    }
  }

.member-sm-frame {
    width: 30vw;
    margin: 5px;
    min-width: 350px;
    background-color: rgb(247, 247, 247);
}

.member-bg {
    position: absolute;
    background-color: var(--blue);
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    animation: extend-right 1s ease-out;
    overflow: hidden;
}


.member-s-bg {
    background-color:  var(--blue);
    opacity: 0.4;
}

.member-s-frame {
    width: 60vw;
}

.member-m-bg {
    background-color: var(--blue);
    opacity: 0.7;
}

.member-m-frame {
    width: 45vw;
}

.member-v-bg {
    background-color: var(--blue);
}

.member-v-frame {
    width: 30vw;
}

@keyframes extend-right {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

.profile-frame {
    width: 25vw;
    margin: 5px;
    min-width: 350px;
    background-color: rgb(247, 247, 247);
}

.join-club-btn-dark {
    font-size: large;
    background-color: var(--blue);
    color: #fff;
    transition: all 0.5s ease;
    padding-left: 30px;
    padding-right: 30px;
}

.join-club-btn-dark:hover {
    background-color: purple;
    color: #fff;
}

.join-club-btn-dark-lh {
    font-size: large;
    background-color: var(--blue);
    color: #fff;
    transition: all 0.5s ease;
    padding-left: 30px;
    padding-right: 30px;
}

.join-club-btn-dark-lh:hover {
    background-color: rgb(255, 255, 255);
    color: var(--blue);
}

/* .gallery-img-frame {
    height: 300px;
    overflow: hidden;
} */

.gallery-img-frame img {
    width: 100%;
}

.footer-frame {
    border-top: #ebebeb 2px solid;
    padding-bottom: 15px;
    padding-top: 15px;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

